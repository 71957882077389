#nav-icon3,
#nav-icon2,
#nav-icon1,
#nav-icon4 {
  width: 35px;
  height: 35px;
  position: relative;

  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  margin-top: 23px;
  margin-bottom: 10px;
  margin-inline-end: auto;
  margin-inline-start: 25px;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;

  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 9px;
}

#nav-icon3 span:nth-child(4) {
  top: 18px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
